import styled, { css } from "styled-components";
import { Flex, theme } from "@mechatronick/datum-design-system";
import PropsTypes from "prop-types";

const FlexWrapper = styled(Flex.withComponent("section")).attrs({
  align: "center",
  justify: "center",
  textAlign: "center",
})`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  align-items: ${props => props.alignItems};
  position: relative;
  z-index: 1;
  max-width: ${props => props.maxWidth};
  margin: auto auto;
  flex-wrap: ${props => props.wrap};
  ${props =>
    props.background
      ? css`
          background: ${theme.colors[props.background] || props.background};
        `
      : null}
  ${props =>
    props.color
      ? css`
          color: ${theme.colors[props.color] || props.color};
        `
      : null}
`;

FlexWrapper.propTypes = {
  background: PropsTypes.string,
  wrap: PropsTypes.oneOf(["wrap", "nowrap", "wrap-reverse"]).isRequired,
  alignItems: PropsTypes.oneOf([
    "flex-start",
    "flex-end",
    "center",
    "baseline",
    "stretch",
  ]),
  maxWidth: PropsTypes.string,
  color: PropsTypes.string,
};

FlexWrapper.defaultProps = {
  background: "transparent",
  alignItems: "flex-start",
  maxWidth: "64em",
  color: "inherit",
};

export default FlexWrapper;
