import React from "react";
import FlexWrapper from "../FlexWrapper";
import { Heading, theme } from "@mechatronick/datum-design-system";
import ElementOfPage from "../ElementOfPage";
import { ELEMENT_TYPE } from "../../constant";
import Pulse from "react-reveal/Pulse";

const inputData = [
  {
    type: ELEMENT_TYPE.TEXT_P,
    text: "Datum Consulting Group (\"us\", \"we\", or \"our\") operates the https://www.datumhq.com/ " +
      "website (the \"Service\"). This page informs you of our policies regarding the collection, " +
      "use, and disclosure of personal data when you use our Service and the choices you " +
      "have associated with that data. We use your data to provide and improve the Service. " +
      "By using the Service, you agree to the collection and use of information in " +
      "accordance with this policy. Unless otherwise defined in this Privacy Policy, " +
      "terms used in this Privacy Policy have the same meanings as in our Terms and " +
      "Conditions, accessible from https://www.datumhq.com/.",
  },
  {
    type: ELEMENT_TYPE.H2,
    text: "Information collection and usage",
  },
  {
    type: ELEMENT_TYPE.TEXT_P,
    text: "While using our Service, we may ask you to provide us with certain personally " +
      "identifiable information that can be used to contact or identify you (\"Personal Data\"). " +
      "Personal identifiable information may include, but is not limited to: Email address, " +
      "Cookies and Usage Data.",
  },
  {
    type: ELEMENT_TYPE.TEXT_P,
    text: "We use cookies and similar tracking technologies to track the activity on our " +
      "Service and hold certain information " +
      "Cookies are files with small amount of data which may include an anonymous unique " +
      "identifier. Cookies are sent to your browser from a website and stored on your device. " +
      "Tracking technologies also used are beacons, tags, " +
      "and scripts to collect and track information and to improve and analyze our Service. " +
      "You can instruct your browser to refuse all cookies or to indicate when a " +
      "cookie is being sent. However, if you do not accept cookies, you may not be able " +
      "to use some portions of our Service.",
  },
  {
    type: ELEMENT_TYPE.H2,
    text: "Use of data",
  },
  {
    type: ELEMENT_TYPE.TEXT_P,
    text: "DATUM CONSULTING GROUP USES THE COLLECTED DATA FOR VARIOUS PURPOSES: " +
      "to provide and maintain the Service, " +
      "to notify you about changes to our Service, " +
      "to allow you to participate in interactive features of our Service when you choose to do so, " +
      "to provide customer care and support, " +
      "to provide analysis or valuable information so that we can improve the Service, " +
      "to monitor the usage of the Service, and " +
      "to detect, prevent and address technical issues.",
  },
  {
    type: ELEMENT_TYPE.H2,
    text: "Transfer of data",
  },
  {
    type: ELEMENT_TYPE.TEXT_P,
    text: "Your information, including Personal Data, may be transferred to — " +
      "and maintained on — computers located outside of your state, province, " +
      "country or other governmental jurisdiction where the data protection " +
      "laws may differ than those from your jurisdiction. If you are located " +
      "outside New Zealand and choose to provide information to us, please " +
      "note that we transfer the data, including Personal Data, to Ukraine and " +
      "process it there. Your consent to this Privacy Policy followed by your " +
      "submission of such information represents your agreement to that transfer. " +
      "Datum Consulting Group will take all steps reasonably necessary to ensure " +
      "that your data is treated securely and in accordance with this Privacy Policy " +
      "and no transfer of your Personal Data will take place to an organization or a " +
      "country unless there are adequate controls in place including the security " +
      "of your data and other personal information.",
  },
  {
    type: ELEMENT_TYPE.H2,
    text: `
        Disclosure of data
        `,
  },
  {
    type: ELEMENT_TYPE.TEXT_P,
    text: "DATUM CONSULTING GROUP MAY DISCLOSE YOUR PERSONAL DATA IN THE GOOD FAITH BELIEF " +
      "THAT SUCH ACTION IS NECESSARY TO: " +
      "to comply with a legal obligation, " +
      "to protect and defend the rights or property of Datum Consulting Group, " +
      "to prevent or investigate possible wrongdoing in connection with the Service, " +
      "to protect the personal safety of users of the Service or the public, " +
      "and to protect against legal liability.",
  },
  {
    type: ELEMENT_TYPE.H2,
    text: `
        Security of data
        `,
  },
  {
    type: ELEMENT_TYPE.TEXT_P,
    text: "The security of your data is important to us, but remember that no method " +
      "of transmission over the Internet, or method of electronic storage is 100% " +
      "secure. While we strive to use commercially acceptable means to protect your " +
      "Personal Data, we cannot guarantee its absolute security.",
  },
  {
    type: ELEMENT_TYPE.H2,
    text: `
        Service providers
        `,
  },
  {
    type: ELEMENT_TYPE.TEXT_P,
    text: `
        We may employ third party companies and individuals to facilitate our Service 
        ("Service Providers"), to provide the Service on our behalf, to perform 
        Service-related services or to assist us in analyzing how our Service is used. 
        These third parties have access to your Personal Data only to perform these tasks 
        on our behalf and are obligated not to disclose or use it for any other purpose.
        `,
  },
  {
    type: ELEMENT_TYPE.TEXT_P,
    text: "We may use third-party Service Providers to monitor and analyze the use of our " +
      "Service including Google Analytics, and MailChimp, all of which " +
      "are compliant with GDPR and other regulations. ",
  },
  {
    type: ELEMENT_TYPE.H2,
    text: `
        Link to other sites
        `,
  },
  {
    type: ELEMENT_TYPE.TEXT_P,
    text: "Our Service may contain links to other sites that are not operated by us. " +
      "If you click on a third party link, you will be directed to that third party's site. " +
      "We strongly advise you to review the Privacy Policy of every site you visit. " +
      "We have no control over and assume no responsibility for the content, " +
      "privacy policies or practices of any third party sites or services.",
  },
  {
    type: ELEMENT_TYPE.H2,
    text: `
        Change to this privacy policy
        `,
  },
  {
    type: ELEMENT_TYPE.TEXT_P,
    text: "We may update our Privacy Policy from time to time. " +
      "We will notify you of any changes by posting the new " +
      "Privacy Policy on this page. We will let you know via " +
      "email and/or a prominent notice on our Service, " +
      "prior to the change becoming effective and update the " +
      "\"effective date\" at the top of this Privacy Policy. " +
      "You are advised to review this Privacy Policy periodically for any changes. " +
      "Changes to this Privacy Policy are effective when they are posted on this page.",
  },
];

const Policy = () => (
  <>
    <Pulse>
      <Heading.h1 style={{ textAlign: "center" }}>
        Privacy policy
      </Heading.h1>
    </Pulse>
    <FlexWrapper
      style={{
        padding: `${theme.space[5]}px ${theme.space[2]}px`,
      }}
      wrap={"wrap"}
    >
      {inputData.map((element, index) => {
        return (
          <ElementOfPage
            key={index}
            {...element}
          />
        );
      })}
    </FlexWrapper>
  </>
);

export default Policy;