import React from "react";
import Triangle from "../Triangle";
import {graphql, useStaticQuery} from "gatsby";
import StyledDivWithCoverControlledDimensions from "../StyledDivWithCoverControlledDimensions";


const Header = () => {
    const {headerImage} = useStaticQuery(
        graphql`
      query {
        headerImage: file(relativePath: { eq: "backgroundPrivacy.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1366) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
    );
    const headerImageSrc = headerImage.childImageSharp.fluid.src;
    return (
        <>
            <StyledDivWithCoverControlledDimensions
                smallScreenHeight={[25, 50]}
                mediumScreenHeight={[30, 40]}
                largeScreenHeight={[30, 40]}
                backgroundImageUrl={headerImageSrc}
                backgroundPosition={"center top"}
            >
                <Triangle
                    color="primary"
                    opacity={0.8}
                    height={[7, 10, 12.5]}
                    width={[105, 110, 115]}
                    invertX
                    invertY
                />
                <Triangle
                    color="lightPrimary"
                    opacity={1}
                    height={[5, 7.5, 10]}
                    width={[100, 100, 100]}
                    invertX
                    invertY
                />
            </StyledDivWithCoverControlledDimensions>
            <StyledDivWithCoverControlledDimensions
                smallScreenHeight={[5]}
                mediumScreenHeight={[7.5]}
                largeScreenHeight={[10]}
            >
                <Triangle
                    color="lightPrimary"
                    opacity={1}
                    height={[5, 7.5, 10]}
                    width={[100, 100, 100]}
                />
            </StyledDivWithCoverControlledDimensions>
        </>
    )
};

export default Header;