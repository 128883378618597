import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Loadable from "react-loadable";
import Loading from "../components/Loading";

import Header from "../components/privacy/Header";
import Policy from "../components/privacy/Policy";

const Nav = Loadable({
  loader: () => import("../components/SharedComponents/Nav"),
  loading: Loading,
});
const ContactForm = Loadable({
  loader: () => import("../components/SharedComponents/ContactFormH1"),
  loading() {
    return null;
  },
});
const SubscribeFooter = Loadable({
  loader: () => import("../components/SharedComponents/SubscribeFooter"),
  loading() {
    return null;
  },
});
const Footer = Loadable({
  loader: () => import("../components/SharedComponents/Footer"),
  loading() {
    return null;
  },
});

export default () => {
  return (
    <Layout>
      <SEO
        title="Privacy Policy | Datum Consulting NZ"
        description={
          "Our terms for privacy policy, information collection, " +
          "use of data, transfer of data, disclosure of data, security, " +
          "service provider, and change of policy."
        }
        keywords={[
          `privacy policy`,
          `information collection`,
          `use of data`,
          `aws`,
          `azure`,
          `software development`,
          `digital transformation`,
          `software delivery`,
          `enterprise support`,
          `it consulting`,
        ]}
        pathname="privacy"
      />
      <Nav />
      <Header />
      <Policy />
      <ContactForm />
      <SubscribeFooter />
      <Footer />
    </Layout>
  );
};
