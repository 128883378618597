import React from "react";
import { ELEMENT_TYPE } from "../constant";
import { Text, Heading } from "@mechatronick/datum-design-system";
import ButtonWithGatsbyLink from "./ButtonWithGatsbyLink";

const ElementOfPage = props => {
  const { type, text, to } = props;
  if (type === ELEMENT_TYPE.TEXT_P) {
    return <Text.p>{text}</Text.p>;
  } else if (type === ELEMENT_TYPE.BUTTON_GATSBY_LINK) {
    return <ButtonWithGatsbyLink to={to}>{text}</ButtonWithGatsbyLink>;
  } else if (type === ELEMENT_TYPE.H2) {
    return <Heading.h2>{text}</Heading.h2>;
  } else if (type === ELEMENT_TYPE.H3) {
    return <Heading.h3>{text}</Heading.h3>;
  }
};

export default ElementOfPage;
